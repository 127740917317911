import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
export default {
  components: {
    Splide,
    SplideSlide
  },
  props: {
    options: {
      type: Object
    },
    slides: {
      type: Array
    }
  }
}
